import IfopNavigation from 'components/IfopNavigation';
import DefaultLayout from './Default';

const LAYOUT_TO_LAYOUT_COMP = {
  IfopNavigation,
};

const chooseLayout = (post) => LAYOUT_TO_LAYOUT_COMP[post.frontmatter.layout] || DefaultLayout;
// eslint-disable-next-line max-len
const chooseNavigation = (post) => LAYOUT_TO_LAYOUT_COMP[post.frontmatter.navigation] || IfopNavigation;
export { chooseLayout, chooseNavigation };
