import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import { graphql } from 'gatsby';
import MiaLayout from 'components/common/MiaLayout';
import { chooseLayout, chooseNavigation } from './OperationLayout';

class MarkdownLayout extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const LayoutComponent = chooseLayout(post);
    const NavigationComponent = chooseNavigation(post);
    return (
      <MiaLayout
        showNewsletterBeforeFooter={false}
      >
        <CharlesMeta
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          image={post.frontmatter.shareImage && post.frontmatter.shareImage.publicURL}
        />
        <LayoutComponent data={this.props.data} navigation={<NavigationComponent />} />
      </MiaLayout>
    );
  }
}

export default MarkdownLayout;

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            fields {
                tagSlugs
            }
            frontmatter {
                title
                description
                tags
                date(formatString: "MMMM DD, YYYY")
                shareImage {
                  publicURL
                }
                wrapperClass
                layout
                navigation
            }
        }
    }
`;
