import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import './styles.sass';

class IfopNavigation extends React.Component {
  render() {
    const { links, title } = this.props;
    return (
      <div className="mt-5 card ifop-card svg-dark-blue border-0 letter-spacing-0">
        <div className="card-header lc-blue text-white pt-3">
          <h4 className="font-weight-normal mb-0">{title}</h4>
        </div>
        <ul className="list-group list-group-flush">
          {links
                        && links.map((link) => (
                          <li
                            key={link.id}
                            className={cx(
                              'list-group-item border-0',
                              link.className,
                            )}
                          >
                            <Link
                              to={link.to}
                              partiallyActive
                              activeClassName="font-weight-bold"
                            >
                              {link.content}
                            </Link>
                          </li>
                        ))}
        </ul>
      </div>
    );
  }
}

export default IfopNavigation;
